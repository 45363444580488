<template>
    <div id="app">
        <nav class="navbar navbar-expand-lg navbar-dark" role="navigation">
            <div class="container">
                <img src="@/assets/Xa_Net_Services.png" class="navbar-brand siteLogo">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <div class="splitter">|</div>
                    </li>
                    <li class="nav-item" v-for="(nav, id) of navigation" :key="id">
                        <router-link active-class="active" :to="{name: nav.url}" class="nav-link">
                            <span class="icon"><font-awesome-icon :icon="nav.icon" /></span>
                            {{nav.name}}
                        </router-link>
                    </li>
                </ul>
                <ul class="nav navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
                            <font-awesome-icon icon="user" /> {{UserInfo.given_name}}
                        </a>
                        <ul class="dropdown-menu dropdown-menu-dark">
<!--                            <li class="dropdown-header">Balance: <font-awesome-icon icon="dollar-sign" /> {{userBalance}}</li>-->
<!--                            <li><hr class="dropdown-divider"></li>-->
<!--                            <li>-->
<!--                                <router-link :to="{name: 'KnowledgeBase'}" class="dropdown-item">-->
<!--                                    <font-awesome-icon icon="book" /> Knowledge Base-->
<!--                                </router-link>-->
<!--                            </li>-->
                            <li>
                                <a class="dropdown-item" href="#" @click="openBugReporter">
                                    <font-awesome-icon class="text-warning" icon="bug" /> Submit Bug Report
                                </a>
                            </li>
                            <li><hr class="dropdown-divider"></li>
                            <li>
                                <a class="dropdown-item" href="#" @click="logout">
                                    <font-awesome-icon icon="door-open" /> Logout
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
        <div :class="`${(widescreen ? 'container-fluid' : 'container mainWindow')}`">
            <router-view/>
        </div>
        <Teleport to="#modals">
            <div ref="toast" class="toast position-absolute bottom-0 mb-4 start-50 translate-middle-x bg-dark" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-header">
                    <strong class="me-auto">{{toastObject.title}}</strong>
                    <small class="text-muted">{{toastObject.subtitle}}</small>
                    <button v-if="toastObject.closeButton" type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div class="toast-body">
                    {{toastObject.text}}
                </div>
            </div>

            <div class="modal modalContainer" ref="pleaseWait" tabindex="-1">
                <div class="modal-dialog">
                    <!--                    <h5 class="modal-title text-center">{{ pleaseWaitObject.title }}</h5>-->
                    <div class="ms-2 fw-bold text-center">{{ pleaseWaitObject.body }}</div>
                    <!--                    <grid-loader :loading="true" color="rgba(32, 116, 238, 0.7)" size="80px" />-->
                </div>
            </div>

            <relayer-model :show="showBugReport" title="Relayer Bug Report" :click-close="true" key="bugReport">
                <template v-slot:content>
                    <label class="form-label">Browser:</label> {{bugReport.browser.browserName}} {{bugReport.browser.fullVersion}}<br />
                    <label class="form-label">Language:</label> {{bugReport.language}}<br />
                    <label class="form-label">Location:</label> {{bugReport.location}}<br />
                    <bootstrap-input
                        label="title"
                        title="Brief Description"
                        help="Please give a brief explanation in less then 100 characters"
                        @valueChange="updateBugReport"
                        :default-value="bugReport.title"
                    />
                    <bootstrap-text-area
                        label="notes"
                        title="Notes"
                        :rows="6"
                        help="What were you doing at the time of the error?"
                        @valueChange="updateBugReport"
                        :default-value="bugReport.notes"
                    />
<!--                    <bootstrap-checkbox-->
<!--                        label="addLogs"-->
<!--                        title="Attach Last 50 lines from log files"-->
<!--                        :default-value="bugReport.attachLogs"-->
<!--                        @valueChange="updateBugReport"-->
<!--                        v-model="bugReport.attachLogs"-->
<!--                    />-->
<!--                    <bootstrap-checkbox-->
<!--                        label="addUserData"-->
<!--                        title="Attach User Data"-->
<!--                        :default-value="bugReport.addUserData"-->
<!--                        @valueChange="updateBugReport"-->
<!--                        v-model="bugReport.addUserData"-->
<!--                    />-->
                </template>
                <template v-slot:footer>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" @click="submitReport">Submit Report</button>
                </template>
            </relayer-model>
        </Teleport>
        <footer class="position-relative bottom-0 mt-5 mb-5 text-center">
            <small>
                <strong>XNS Relayer:</strong> v{{Cache.SystemVersion}} {{Cache.BuildState !== 'stable' ? `(Beta/Nightly Release) Build: ${build}` : ''}} |
                <strong>HostIO:</strong> v{{versions.HostIO}} |
                <strong>Gateway:</strong> v{{versions.Gateway}} |
                <strong>MinIO:</strong> v{{versions.MinIO}}<br />
            </small>
            Copyright&copy; 2022 - {{new Date().getFullYear()}} SCP Corp. | <a href="https://scpri.me/" target="_blank">ScPrime</a> | <a href="https://xns.tech/" target="_blank">Xa Net Services</a> | <a href="https://console.xns.tech/billing" target="_blank">XNS Console</a>
        </footer>
    </div>
</template>

<script>
import { Toast, Modal } from 'bootstrap';
import 'bootstrap/js/dist/dropdown';

export default {
    name: "MainTheme",
    created() {
        this.BUS.on('submitBug', () => {
            this.openBugReporter();
        });
        this.BUS.on('EnableWidescreen', () => {
            console.log("Widescreen Received...");
            this.widescreen = true;
        });
    },
    mounted() {
        this.pleaseWaitObject.entity = new Modal(this.$refs.pleaseWait, {keyboard: false, backdrop: 'static'});

        this.BUS.on('toast', (options) => {
            if (options === undefined || options === null) {
                this.toastObject = {
                    show: false,
                    title: '',
                    subtitle: '',
                    text: '',
                    delay: 0,
                    type: 'success',
                    closeButton: true,
                }
            } else {
                this.toastObject = {
                    show: true,
                    title: options.title || 'XNS Relayer',
                    subtitle: options.subtitle || '',
                    text: options.text || 'No text added...',
                    delay: options.delay || 0,
                    type: options.type || 'success',
                    closeButton: options.closeButton || true,
                }
            }

            let addCssRole = '';
            switch (this.toastObject.type) {
                case "success":
                    addCssRole = 'toastSuccess';
                    break;
                case "warning":
                    addCssRole = 'toastWarning';
                    break;
                case "info":
                    addCssRole = 'toastInfo';
                    break;
            }

            this.$refs.toast.classList.remove('toastSuccess', 'toastWarning', 'toastInfo');
            this.$refs.toast.classList.add(addCssRole);
            this.toastObject.show ? this.showToast(this.toastObject.delay) : this.hideToast();
        });
        this.BUS.on('pleaseWait', (options) => {
            if (options === null) {
                this.hidePleaseWait();
            } else {
                this.showPleaseWait(options.message, options.title);
            }
        });
        this.BUS.on('modal-closed', () => {
            this.showBugReport = false;
            this.bugReport = {
                location: this.$route.path,
                browser: this.processUserAgent(),
                language: navigator.language,
                userInfo: this.UserInfo,
                title: '',
                notes: '',
                attachLogs: true,
            };
        });
        setTimeout(this.getBalance.bind(this), 200);
    },
    computed: {
        userBalance() {
            if (this.balance === 0) {
                return "0.00";
            }
            return parseFloat(this.balance.amount / 100).toFixed(2);
        }
    },
    methods: {
        async getBalance() {
            let balance = await this.AJAX.get('system/balance');
            if (balance.success) {
                this.balance = balance.amount;
                this.balanceError = null;
            } else {
                this.balance = 0;
                this.balanceError = balance.message;
            }
        },
        logout() {
            if (confirm('Are you sure you wish to logout?')) {
                this.$keycloak.logoutFn();
            }
        },
        showPleaseWait(message, title = null) {
            if (message === undefined || message === null) {
                this.hidePleaseWait();
            } else {
                this.pleaseWaitObject.show = true;
                this.pleaseWaitObject.body = message;
                this.pleaseWaitObject.title = title || 'XNS Relayer';
                this.pleaseWaitObject.entity.show();
            }
        },
        hidePleaseWait() {
            this.pleaseWaitObject.show = false;
            this.pleaseWaitObject.body = 'Please wait... Processing request...';
            this.pleaseWaitObject.title = 'XNS Relayer';
            this.pleaseWaitObject.entity.hide();
        },
        updateBugReport(key, value) {
            if (key === 'notes') {
                this.bugReport.notes = value;
            } else if (key === 'addLogs') {
                this.bugReport.attachLogs = value;
            } else if (key === 'addUserData') {
                this.bugReport.addUserData = value;
            } else if (key === 'title') {
                this.bugReport.title = value;
            }
        },
        openBugReporter() {
            this.bugReport = {
                location: this.$route.path,
                browser: this.processUserAgent(),
                language: navigator.language,
                userInfo: this.UserInfo,
                title: '',
                notes: '',
                attachLogs: true,
                addUserData: true,
            };
            delete this.bugReport.userInfo.token;
            this.$nextTick(() => {
                this.showBugReport = true;
            });
        },
        submitReport() {
            this.$nextTick(() => {
                this.showBugReport = false;
                this.AJAX.post('system/bug-report', this.bugReport).then(() => {
                    this.Toast.quick('Bug report submitted successfully.');
                }).catch((e) => {
                    this.Toast.quick(e.message, 'warning');
                });
            });
        },
        showToast(delay) {
            let options = {};
            if (delay === undefined || delay === '' || delay === 0) {
                options = {
                    autohide: false,
                }
            } else {
                options = {
                    delay: Number(delay),
                }
            }
            let temp = new Toast(this.$refs.toast, options);
            temp.show();
        },
        hideToast() {
            let temp = new Toast(this.$refs.toast, {});
            temp.hide();
        },
        processUserAgent() {
            let nAgt = navigator.userAgent;
            let browserName  = navigator.appName;
            let fullVersion  = ''+parseFloat(navigator.appVersion);
            let majorVersion = parseInt(navigator.appVersion,10);
            let nameOffset,verOffset,ix;

            // In Opera, the true version is after "Opera" or after "Version"
            if ((verOffset=nAgt.indexOf("Opera"))!==-1) {
                browserName = "Opera";
                fullVersion = nAgt.substring(verOffset+6);
                if ((verOffset=nAgt.indexOf("Version"))!==-1)
                    fullVersion = nAgt.substring(verOffset+8);
            }
            // In MSIE, the true version is after "MSIE" in userAgent
            else if ((verOffset=nAgt.indexOf("MSIE"))!==-1) {
                browserName = "Microsoft Internet Explorer";
                fullVersion = nAgt.substring(verOffset+5);
            }
            // In Chrome, the true version is after "Chrome"
            else if ((verOffset=nAgt.indexOf("Chrome"))!==-1) {
                browserName = "Chrome";
                fullVersion = nAgt.substring(verOffset+7);
            }
            // In Safari, the true version is after "Safari" or after "Version"
            else if ((verOffset=nAgt.indexOf("Safari"))!==-1) {
                browserName = "Safari";
                fullVersion = nAgt.substring(verOffset+7);
                if ((verOffset=nAgt.indexOf("Version"))!==-1)
                    fullVersion = nAgt.substring(verOffset+8);
            }
            // In Firefox, the true version is after "Firefox"
            else if ((verOffset=nAgt.indexOf("Firefox"))!==-1) {
                browserName = "Firefox";
                fullVersion = nAgt.substring(verOffset+8);
            }
            // In most other browsers, "name/version" is at the end of userAgent
            else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < (verOffset=nAgt.lastIndexOf('/')) ) {
                browserName = nAgt.substring(nameOffset,verOffset);
                fullVersion = nAgt.substring(verOffset+1);
                if (browserName.toLowerCase()===browserName.toUpperCase()) {
                    browserName = navigator.appName;
                }
            }
            // trim the fullVersion string at semicolon/space if present
            if ((ix=fullVersion.indexOf(";"))!==-1)
                fullVersion=fullVersion.substring(0,ix);
            if ((ix=fullVersion.indexOf(" "))!==-1)
                fullVersion=fullVersion.substring(0,ix);

            majorVersion = parseInt(''+fullVersion,10);
            if (isNaN(majorVersion)) {
                fullVersion  = ''+parseFloat(navigator.appVersion);
                majorVersion = parseInt(navigator.appVersion,10);
            }
            return {
                browserName: browserName,
                fullVersion: fullVersion,
                majorVersion: majorVersion,
                agent: navigator.userAgent,
            }
        }
    },
    data: () => ({
        versions: {
            HostIO: '0.5.0',
            Gateway: '0.5.0',
            MinIO: '0.5.0',
        },
        build: '20231108171420',
        widescreen: false,
        toast: null,
        toastObject: {
            show: false,
            title: '',
            subtitle: '',
            text: '',
            delay: 0,
            type: 'success',
            closeButton: true,
        },
        modalObject: {
            options: {

            },
            show: false,
            showTitle: false,
            message: {
                title: '',
                body: '',
            },
            actions: null,
        },
        pleaseWaitObject: {
            entity: null,
            show: false,
            title: 'XNS Relayer',
            body: 'Please wait... Processing request...',
        },
        navigation: [
            {name: 'Dashboard', url: 'Dashboard', icon: 'tachometer-alt'},
            {name: 'Configuration', url: 'Configuration', icon: 'sliders-h'},
            {name: 'IAM', url: 'IAM', icon: 'users'},
            {name: 'Provider Profiles', url: 'ProviderProfiles', icon: 'earth'},
            // {name: 'PP2', url: 'ProviderProfiles2', icon: 'earth', dev: true},
            {name: 'File Manager', url: 'FileManager', icon: 'folder-open'},
            {name: 'Logs', url: 'Logs', icon: 'clipboard-list'},
            {name: 'System', url: 'System', icon: 'cog'},
        ],
        balance: 0,
        balanceError: null,
        showBugReport: false,
        bugReport: {
            location: '',
            browser: '',
            language: '',
            userInfo: {},
            title: '',
            notes: '',
            attachLogs: true,
            addUserData: true,
        }
    })
}
</script>

<style scoped>

</style>
